import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationService, IMenuItem, IChildItem } from '../../../../services/navigation.service';
import { CommonService } from '../../../../../dce-service/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, buffer } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { SharedService } from 'src/app/dce-service/shared.service';

@Component({
  selector: 'app-sidebar-large',
  templateUrl: './sidebar-large.component.html',
  styleUrls: ['./sidebar-large.component.scss']
})
export class SidebarLargeComponent implements OnInit {

    selectedItem: IMenuItem;

	nav: IMenuItem[];
	show_top_btn = false;
 	show_down_btn = true;
 	hide_child_top_btn = true;
 	hide_child_down_btn = true;

	constructor(
		public router: Router,
		public navService: NavigationService,
		public _commonService: CommonService,
		private library: FaIconLibrary,
		private _shared: SharedService
	) {
		library.addIconPacks(fas);
	 }

	ngOnInit() {
		this.updateSidebar();
		// CLOSE SIDENAV ON ROUTE CHANGE
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
				}
			});

		/*this.navService.menuItems$
			.subscribe((items) => {
                this.nav = items;
				this.setActiveFlag();
			});*/

		this.process_sidebar();
		this._shared.get_sidebar_tree();
	}

	// get_sidebar_tree(){
	// 	this._commonService.get_sidebar_tree({}).subscribe(items => {
	// 		var nav: IMenuItem[] = items.sidebar_tree;
	// 		this.nav = nav;
	// 		this.setActiveFlag();
	// 		this.count_sidebar_items();
	// 	});
	// }
	process_sidebar(){
		this._shared.sidebar_tree_pipeline.subscribe((tree: any)=>{
			if(tree.length) {
				var nav: IMenuItem[] = tree;
				this.nav = nav;
				this.setActiveFlag();
				this.count_sidebar_items();
			}
		})
	}

	count_sidebar_items(){
 		if(document.getElementById('navigation-left1').clientHeight >= (134*this.nav.length)){
 			this.show_down_btn = false;
 		}else{
 			this.show_down_btn = true;
 			document.getElementById('slideDown').style.top = (document.getElementById('navigation-left1').clientHeight-20) + 'px'
 		}
 	}

	selectItem(item) {
		this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
		this.setActiveMainItem(item);
	}
	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		this.setActiveMainItem(item);
		if(item.type == "dropDown"){
 			this.show_child_sidebar_buttons()
 		}
	}
	setActiveMainItem(item) {
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
		this.scroll_height()
	}

	setActiveFlag() {
		if (window && window.location) {
            const activeRoute = window.location.hash || window.location.pathname;
			this.nav.forEach(item => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
                    this.selectedItem = item;
					item.active = true;
				}
				if (item.sub) {
					item.sub.forEach(subItem => {
                        subItem.active = false;
						if (activeRoute.indexOf(subItem.state) !== -1) {
                            this.selectedItem = item;
                            item.active = true;
                        }
                        if (subItem.sub) {
                            subItem.sub.forEach(subChildItem => {
                                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                                    this.selectedItem = item;
                                    item.active = true;
                                    subItem.active = true;
                                }
                            });
                        }
					});
				}
	});
		}
    }

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
			this.navService.sidebarState.childnavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	slide(id){
 
 		var sidebar_item = document.getElementById('n').clientHeight
 		var t2 = document.getElementById('navigation-left1').clientHeight
 		var h = (t2)/2
 		var x = Math.round(t2/sidebar_item)
 		if(id == 'slideTop'){
 			if(x!=1){
 				document.getElementById('navigation-left1').scrollTop -= (134*(x-1));
 			}else{
 				document.getElementById('navigation-left1').scrollTop -= (134*x);
 			}
 		}else{
 			if(x!=1){
 				document.getElementById('navigation-left1').scrollTop += (134*(x-1));
 			}else{
 				document.getElementById('navigation-left1').scrollTop += (134*x);
 			}
 		}
 		this.scroll_height()
 	}
 
 	scrollHandler(event){
 		// console.log(event)
 		this.scroll_height()
 	}
  
 	scroll_height(){
 		var t1 = document.getElementById('navigation-left1').scrollHeight
 		var t2 = document.getElementById('navigation-left1').clientHeight
 		var h = t1-t2
 		if(h == (document.getElementById('navigation-left1').scrollTop)){
 			this.show_down_btn = false
 		}else{
 			this.show_down_btn = true
 		}
 		if(document.getElementById('navigation-left1').scrollTop == 0){
 			this.show_top_btn = false
 			document.getElementById('slideDown').style.top = (document.getElementById('navigation-left1').clientHeight-20) + 'px'
 
 		}else{
 			this.show_top_btn = true
 			document.getElementById('slideDown').style.top = (document.getElementById('navigation-left1').clientHeight-26) + 'px'
 		}
 	}
 
 	scrollHandler_child(event){
 		console.log(event)
 		this.show_child_sidebar_buttons()	
 	}
 
 	slide_child_sidebar(id){
 		if(id == 'slide_child_top'){
 			document.getElementById('child-menu').scrollTop -= 100;
 		}else{
 			document.getElementById('child-menu').scrollTop += 100;
 		}
 
 		this.show_child_sidebar_buttons();
 	}
 
 	show_child_sidebar_buttons(){
 		console.log('yes')
 		var t1 = document.getElementById('level1-nav').scrollHeight
 		var t2 = document.getElementById('child-menu').clientHeight
 		var h = t1-t2
 		if(h <= (document.getElementById('child-menu').scrollTop - 16)){
 			this.hide_child_down_btn = true
 		}else{
 			this.hide_child_down_btn = false
 		}
 		if(document.getElementById('child-menu').scrollTop == 0){
 			this.hide_child_top_btn = true
 		}else{
 			this.hide_child_top_btn = false
 		}
 	}
 
 	calculate_level2_child_sidebar_item(data){
 		setTimeout(()=>{
 			console.log(document.getElementById('child-menu').scrollHeight)
 			console.log(document.getElementById('level1-nav').scrollHeight)
 			this.show_child_sidebar_buttons()
 		}, 500)
 	}
 
	 

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
    }

}
