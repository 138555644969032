import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-side-container',
  templateUrl: './side-container.component.html',
  styleUrls: ['./side-container.component.scss']
})
export class SideContainerComponent implements OnInit {
  @Input() container_width: string;
  @Output() side_div_events = new EventEmitter();
  @Input() container_title = '';
  @Input() unique_id? = '';
  @Input() date_visible?:boolean=false;
  @Input() date?:Date=null;

  constructor(private _elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  openContainer() {
    $('#sideContainer' + this.unique_id).css('border-left', '2px solid #294178');
    $('#sideContainer' + this.unique_id).width(this.container_width);
    // $('#main').css('background-color', 'rgba(0,0,0,0.4)');
  }

  closeContainer() {
    this.side_div_events.emit({"type": "close"})
    $('#sideContainer' + this.unique_id).css('border', '0');
    $('#sideContainer' + this.unique_id).width('0');
    // $('#main').css('background-color', 'white');
  }

}
