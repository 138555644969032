import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { Resolver } from './resolver';
// import { ProjectRoutes } from './project-app-routing.module';

let adminRoutes: Routes = [
  // Moses Routes
    {
      path: 'emp-data-load',
      loadChildren: () => import('./dce-views/data-load/data-load.module').then(m => m.DataLoadModule)
    },
    {
      path: 'busorg',
      loadChildren: () => import('./dce-views/busorg/busorg.module').then(m => m.BusorgModule)
    },
    {  
      path: 'portfolio',
      loadChildren: () => import('./dce-views/portfolio/portfolio.module').then(m => m.PortfolioModule)
    },
    {
      path: 'configurations',
      loadChildren: () => import('./dce-views/configurations/configurations.module').then(m => m.ConfigurationsModule)
    },
    {
      path: 'classification',
      loadChildren: () => import('./dce-views/classification/classification.module').then(m => m.ClassificationModule)
    },
    {
      path: 'roles',
      loadChildren: () => import('./dce-views/roles/roles.module').then(m => m.RolesModule)
    },
    {
      path: 'user-profile',
      loadChildren: () => import('./dce-views/user-profile/user-profile.module').then(m => m.UserProfileModule)
    },
    {
      path: 'notifications',
      loadChildren: () => import('./dce-views/notifications/notifications.module').then(m => m.NotificationsModule)
    },
    {
      path: 'internal-org',
      loadChildren: () => import('./dce-views/internal-org/internal-org.module').then(m => m.InternalOrgModule)
    },
    {
      path: 'workgroups',
      loadChildren: () => import('./dce-views/work-groups/work-groups.module').then(m => m.WorkGroupsModule)
    }, 
    {
      path: 'case-management',
      loadChildren: () => import('./dce-views/case-management/case-management.module').then(m => m.CaseManagementModule)
    },
    {
      path: 'knowledge-management-search',
      loadChildren: () => import('./dce-views/knowledge-management-search/knowledge-management-search.module').then(m => m.KnowledgeManagementSearchModule)
    }, 
    {
      path: 'lead-management',
      loadChildren: () => import('./dce-views/lead-management/lead-management.module').then(m => m.LeadManagementModule)
    }, 
    {
      path: 'offerings',
      loadChildren: () => import('./dce-views/item-master/item-master.module').then(m => m.ItemMasterModule)
    },
    {
      path: 'sales-order',
      loadChildren: () => import('./dce-views/sales-order/sales-order.module').then(m => m.SalesOrderModule)
    },
    {
      path: 'task',
      loadChildren: () => import('./dce-views/task-management/task-management.module').then(m => m.TaskManagementModule)
    },
    {
      path: 'task-shared',
      loadChildren: () => import('./dce-views/task-shared/task-shared.module').then(m => m.TaskSharedModule)
    },
    {
      path: 'analytics',
      loadChildren: () => import('./dce-views/analytics/analytics.module').then(m => m.AnalyticsModule)
    },
    {
      path: 'biz-obj-workflow',
      loadChildren: () => import('./dce-views/business-object-workflow/business-object-workflow.module').then(m => m.BusinessObjectWorkflowModule)
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'uikits',
      loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
    },
    {
      path: 'forms',
      loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
    },
    {
      path: 'invoice',
      loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'calendar',
      loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'tables',
      loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    },
    {
      path: 'icons',
      loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    },
    {
      path: 'cms',
      loadChildren: () => import('./dce-views/case-management-setup/case-management-setup.module').then(m => m.CaseManagementSetupModule)
    },
    {
      path: 'dispatch-rule',
      loadChildren: () => import('./dce-views/dispatch-rule/dispatch-rule.module').then(m => m.DispatchRuleModule)
    },
    {
      path: 'assignment-rule',
      loadChildren: () => import('./dce-views/assignment-rule/assignment-rule.module').then(m => m.AssignmentRuleModule)
    },
    {
      path: 'action-rule',
      loadChildren: () => import('./dce-views/workflow-action-config/workflow-action-config.module').then(m => m.WorkflowActionConfigModule)
    },
    {
      path: 'config',
      loadChildren: () => import('./dce-views/lg-config/lg-config.module').then(m => m.LgConfigModule)
    },
    {  
      path: 'gems',
      loadChildren: () => import('./dce-views/gems-fna/gems-fna.module').then(m => m.GemsFnaModule)
    },
    {
      path: 'social-media',
      loadChildren: () => import('./dce-views/social-media/social-media.module').then(m => m.SocialMediaModule)
    },

  // Project Routes
    {
      path: 'fna',
      loadChildren: () => import('./dce-views/fna/fna.module').then(m => m.FnaModule)
    },
    {
      path: 'product-conf',
      loadChildren: () => import('./dce-views/product-configuration/product-configuration.module').then(m => m.ProductConfigurationModule)
    },
    {
      path: 'knowledge-management',
      loadChildren: () => import('./dce-views/knowledge-management/knowledge-management.module').then(m => m.KnowledgeManagementModule)
    },
    {
      path: 'holiday',
      loadChildren: () => import('./dce-views/holiday-list/holiday-list.module').then(m => m.HolidayListModule)
    },
    {
      path: 'sip',
      loadChildren: () => import('./dce-views/jssip/jssip.module').then(m => m.JssipModule)
    },
    {  
      path: 'checklist',
      loadChildren: () => import('./dce-views/case-document-checklist/case-document-checklist.module').then(m => m.CaseDocumentChecklistModule)
    },
    {  
      path: 'meeting',
      loadChildren: () => import('./dce-views/meeting/meeting.module').then(m => m.MeetingModule)
    },
    {  
      path: 'task-form',
      loadChildren: () => import('./dce-views/task-form/task-form.module').then(m => m.TaskFormModule)
    },
    {
      path: 'campaign-management',
      loadChildren: () => import('./dce-views/campaign-management/campaign-management.module').then(m => m.CampaignManagementModule)
    },
    {
      path: 'logs',
      loadChildren: () => import('./dce-views/logs/logs.module').then(m => m.LogsModule)
    },
    {
      path: 'wss-config',
      loadChildren: () => import('./dce-views/web-self-services/web-self-services.module').then(m => m.WebSelfServicesModule)
    },

    {
      path: 'email-conf', loadChildren: ()=>import('./dce-views/email-conf/email-conf.module').then(m => m.EmailConfModule)
    },
    {
      path: 'queue-management',
      loadChildren: () => import('./dce-views/queue-management/queue-management.module').then(m => m.QueueManagement)
    },
    {
      path: 'live-chat',
      loadChildren: () => import('./dce-views/live-chat/live-chat.module').then(m => m.LiveChatModule)
    },
    {
      path: 'chat-dshb',
      loadChildren: () => import('./dce-views/chat-dshb/chat-dshb.module').then(m => m.ChatDshbModule)
    },
    {
      path: 'procedure',
      loadChildren: () => import('./dce-views/procedure/procedure.module').then(m => m.ProcedureModule)
    },
    {
      path: 'manage-telephony',
      loadChildren: () => import ('./dce-views/tele-management/tele-management.module').then( m => m.TeleManagementModule)
    },
    {
      path: 'agent-task',
      loadChildren: () => import('./dce-views/agent-task/agent-task.module').then(m => m.AgentTaskModule)
    },
    {
      path:'follow-up-task',
      loadChildren: ()=> import ('./dce-views/follow-up-task/follow-up-task.module').then(m=>m.FollowUpTaskModule)
    }
      




];

// adminRoutes.push({
//   path: 'classification',
//   loadChildren: () => import('./dce-views/classification/classification.module').then(m => m.ClassificationModule)
// });

// console.log(adminRoutes);debugger

// adminRoutes.unshift(...ProjectRoutes);
// adminRoutes = adminRoutes.concat(ProjectRoutes);
// console.log(adminRoutes);debugger

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      },
      {
        path: 'survey',
        loadChildren: () => import('./dce-views/survey/survey.module').then(m => m.SurveyModule)
      },
      {  
        path: 'meeting-logout',
        loadChildren: () => import('./dce-views/meeting-logout-survey/meeting-logout-survey.module').then(m => m.MeetingLogoutSurveyModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    resolve: { roles_map: Resolver },
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes, 
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
