import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DceSharedRoutingModule } from './dce-shared-routing.module';
import { TreeComponent } from './tree/tree.component';
import { VisComponent } from './vis/vis.component';
import { AddressComponent } from './address/address.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DatatableWidgetComponent } from './datatable-widget/datatable-widget.component';
import { AddLogsComponent } from './add-logs/add-logs.component';
import { NgCompleterComponent } from './ng-completer/ng-completer.component';
import { AddPotMemComponent } from './add-pot-mem/add-pot-mem.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { IndividualCustomersPayloadComponent } from './individual-customers-payload/individual-customers-payload.component';
import { AgmCoreModule } from '@agm/core';
import { CodemirrorModule } from 'ng2-codemirror';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NzRoleDirective } from './directives/nz-role.directive';
import { ButtonClickDirective } from '../dce-directive/button-click.directive';
import { LowerCaseTextDirective } from '../dce-directive/lower-case-text.directive';
import { AngularDraggableModule } from 'angular2-draggable';
import { TableWidgetComponent } from './table-widget/table-widget.component';
import { CamelCaseWithSplitPipe } from './pipes/camel-case-with-split.pipe';
import { CamelCaseWithoutOrderPipe } from './pipes/camel-case-without-order.pipe';
import { CancelEditComponent } from './cancel-edit/cancel-edit.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DependentsComponent } from './dependents/dependents.component';
import { InfoComponent } from './info/info.component';
import { FilterComponent } from './filter/filter.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { ClearSearchComponent } from './clear-search/clear-search.component';
import { DatatableSearchComponent } from './datatable-search/datatable-search.component';
import { FilterRuleTypeComponent } from './filter-rule-type/filter-rule-type.component';
import { SideContainerComponent } from './side-container/side-container.component';
import { Ng2CompleterModule } from "ng2-completer";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { QuillModule } from 'ngx-quill';
import { Ng5SliderModule } from 'ng5-slider';
import { NotesReplyComponent } from './notes-reply/notes-reply.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { BusWrkflwFormsAnsComponent } from './bus-wrkflw-forms-ans/bus-wrkflw-forms-ans.component';
import { BusOrgDetailsPayloadComponent } from './bus-org-details-payload/bus-org-details-payload.component';
import { GenericButtonComponent } from './generic-button/generic-button.component';
import { NgCompleterV2Component } from './ng-completer-v2/ng-completer-v2.component';
import { SelectCustomerComponent } from './select-customer/select-customer.component';
import { CopyTextComponent } from './copy-text/copy-text.component';
import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner';
import { ANIMATION_TYPES } from 'ng2-loading-spinner';
import { TaskClassificationComponent } from './task-classification/task-classification.component';
import { TagsComponent } from './tags/tags.component';
import { StatusFormatPipe } from './pipes/status-format.pipe';
//import { PaginatedSearchComponent } from './paginated-search/paginated-search.component';
import { MultiLevelClassificationComponent } from './multi-level-classification/multi-level-classification.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { EmailInputComponent } from './email-input/email-input.component';
import { LinkedTasksComponent } from './linked-tasks/linked-tasks.component';
import { TaskDetailsComponent } from './task-details/task-details.component';
import { PagniatedDatatableComponent } from './pagniated-datatable/pagniated-datatable.component';
import { InputNumberDirective } from '../dce-directive/input-number.directive';
import { InteractionsComponent } from './interactions/interactions.component';
import { TogglerComponent } from './toggler/toggler.component';
import { GenericSurveyjsModule } from 'src/app/dce-views/generic-surveyjs/generic-surveyjs.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BtnLoaderDirective } from '../dce-directive/btn-loader.directive';
import { InPlaceConfirmationComponent } from './in-place-confirmation/in-place-confirmation.component';
import { ClipboardKeyParserComponent } from './clipboard-key-parser/clipboard-key-parser.component';
import { WfAggregateLogsComponent } from './wf-aggregate-logs/wf-aggregate-logs.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { ResetMpinComponent } from './reset-mpin/reset-mpin.component';
//import { UserTaskRecordComponent } from './user-task-record/user-task-record.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const components = [
  TreeComponent,
  VisComponent,
  AddressComponent,
  BreadcrumbComponent,
  ShowErrorsComponent,
  ContactComponent,
  LoginComponent,
  ChangePasswordComponent,
  DatatableWidgetComponent,
  NzRoleDirective,
  ButtonClickDirective,
  LowerCaseTextDirective,
  InputNumberDirective,
  TableWidgetComponent,
  CancelEditComponent,
  DependentsComponent,
  InfoComponent,
  UploadDocumentComponent,
  FilterComponent,
  AddLogsComponent,
  ConfirmationModalComponent,
  NgCompleterComponent,
  AddPotMemComponent,
  SideContainerComponent,
  AuditLogsComponent,
  NotesReplyComponent,
  AdvanceSearchComponent,
  BusWrkflwFormsAnsComponent,
  IndividualCustomersPayloadComponent,
  BusOrgDetailsPayloadComponent,
  ClearSearchComponent,
  DatatableSearchComponent,
  FilterRuleTypeComponent,
  GenericButtonComponent,
  NgCompleterV2Component,
  SelectCustomerComponent,
  CaseDetailsComponent,
  TaskDetailsComponent,
  LinkedTasksComponent,
  CopyTextComponent,
  TaskClassificationComponent,
  TagsComponent,
  MultiLevelClassificationComponent,
  //PaginatedSearchComponent,
  EmailInputComponent,
  PagniatedDatatableComponent,
  StatusFormatPipe,
  CamelCaseWithSplitPipe,
  CamelCaseWithoutOrderPipe,
  TimeAgoPipe,
  DateFormatPipe,
  InteractionsComponent,
  TogglerComponent,
  BtnLoaderDirective,
  InPlaceConfirmationComponent,
  ClipboardKeyParserComponent,
  WfAggregateLogsComponent,
  TagInputComponent,
  PinInputComponent,
  ResetMpinComponent,
  //UserTaskRecordComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    DceSharedRoutingModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    AngularDraggableModule,
    Ng2CompleterModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule,
    QuillModule,
    Ng5SliderModule,
    GenericSurveyjsModule,
    AgmCoreModule.forRoot({
      apiKey: ''
    }),
    NgxMaskModule.forRoot(maskConfig),
    NgSelectModule,
    Ng2LoadingSpinnerModule.forRoot({animationType  : ANIMATION_TYPES.dualCircle, spinnerSize: 'md'})
  ],
  exports: [
  components,
  CodemirrorModule,
  NzRoleDirective,
  FontAwesomeModule,
  Ng2LoadingSpinnerModule,
  NgSelectModule,
  ]
})
export class DceSharedModule { }
