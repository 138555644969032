import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { CommonService } from '../../dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { colorConfig, surveyOptions, curStrings, surveyStrings, css_classes, showingProperties } from 'src/app/dce-views/generic-surveyjs/generic-surveyjs.model';
import { GenericSurveyjsComponent } from 'src/app/dce-views/generic-surveyjs/generic-surveyjs/generic-surveyjs.component';
declare var $: any;

@Component({
  selector: 'app-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss']
})
export class TableWidgetComponent implements OnInit {
  @ViewChild(GenericSurveyjsComponent, { static: false }) surveyChild: GenericSurveyjsComponent;

  @Input() tableCase: string = '';
  @Input() dataList = [];
  @Input() note_case?: string = 'case';
  @Input() msg?: string = '';
  @Input() max_height?: string = '50vh';

  @Output() modal_data?: EventEmitter<any> = new EventEmitter<any>(); // emit the data of nodes and edges
  survey_data:any = []
  toggle_expand = false;

  survey_questionaire:any ={};
  survey_answers: any = {};
  survey_config = {
    form_mode: 'read_mode',
    form_answers: null,
    form_json: null,
    survey_identifier: 'default',
    color_config: colorConfig['default'],
    surveyOptions: surveyOptions['default'],
    surveyStrings: {
      creator_options: {
        completingSurvey: "Your response has been saved.",
        completeText: "Complete",
        emptySurvey: "Fetching Survey Details"
      },
      display_options: {
        completingSurvey: "Your response has been saved.",
        completeText: "Complete",
        emptySurvey: "Fetching Survey Details"
      }
    },
    curStrings: curStrings['default'],
    css_classes: css_classes['default'],
    showingProperties: showingProperties['default']
  };
  constructor(
    private _common_service: CommonService,
    private _env: EnvService,
    private sharedService: SharedService,
    private _toastr:ToastrService,
    private _toastrUtility:ToastrUtilityService
  ) { }

  ngOnInit() {
  }

  fetch_note_docs(index, note_case, note_id,id_value){
		var payload = {
			"obj_type": note_case+"_view", 
			"note_id": note_id
		};
		this._common_service.get_workflow_notes_documents(payload).subscribe(res => {
			if(res.errCode == 0){
				this.view_doc_links(res.doc_data, id_value,index);
			}
		});
  }

  fetch_noti_note_docs(index, id_array,id_value){
    var payload = {
      "document_id": id_array
    };
    this._common_service.get_notfication_documents(payload).subscribe(res => {
      if(res.errCode == 0){
        this.view_doc_links(res.doc_data,id_value,index);
      }
    });
  }

  view_doc_links(doc_data,id_value, index) {
    var html_links = '';
    html_links += '<ul style="padding-left: 17px;margin-bottom:6px">';
    let regex_string = '';
    for (let i = 0; i <this._env.show_file_in_browser.length; i++) {
      if ( i != this._env.show_file_in_browser.length -1) {
        regex_string += this._env.show_file_in_browser[i] + '|'
      } else {
        regex_string += this._env.show_file_in_browser[i];
      }
    }
    var regex = new RegExp("[.](" + regex_string + ")");
    for(var i=0; i<doc_data.length; i++){
      if (regex.test(doc_data[i]['document_link'])) {
        // html_links += "<li><a target='_blank' href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' id='" + doc_data[i]['document_link'] + "$"  + doc_data[i]['document_name'] +"'>" + "(Download)" + "</span></li>";
        html_links += "<li><a target='_blank' href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' class='fas fa-file-download' title='Click to download document' id='" + doc_data[i]['document_link'] + "$" + doc_data[i]['document_name'] + "'></span></li>";
      } else {
        // html_links += "<li><a target='_blank' download='" + doc_data[i]['document_name'] + "'" + "href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' id='" + doc_data[i]['document_link'] + "$"  + doc_data[i]['document_name'] +"'>" + "(Download)" + "</span></li>";
        html_links += "<li><a target='_blank' download='" + doc_data[i]['document_name'] + "'" + "href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' class='fas fa-file-download' title='Click to download document' id='" + doc_data[i]['document_link'] + "$" + doc_data[i]['document_name'] + "'></span></li>";
      }
    }
    html_links += '</ul>';
    if (doc_data.length) {
      $('#'+id_value+index).html(html_links);
    }else  {
      const no_doc_msg = 'No documents found';
      $('#'+id_value+index).html(no_doc_msg);
    }
    const self = this;
    for (var i = 0; i < doc_data.length; i++) {
      document.getElementById(doc_data[i]['document_link'] + '$' + doc_data[i]['document_name']).addEventListener("click", function(event) {
        const file_data = event.target['id'].split('$');
        const url = file_data[0]
				self.sharedService.spinnerVisibility(true);
        self._common_service.get_b64_doc_from_url({url:url}).subscribe(res => {
          if(res.errCode == 0) {
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.mimetype + ';base64,' + res.data);
            link.setAttribute('download', file_data[1]);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          self.sharedService.spinnerVisibility(false);
        })
      });
    }
  }

  show_modal(index) {
    this.modal_data.emit(this.dataList[index]);
  }

  copy_note_text_clipboard(note_text) {
    let el_div = document.createElement('div');
    el_div.setAttribute("id", "note_text");
    el_div.innerHTML = note_text.trim();
    document.body.appendChild(el_div);
    // el_div.select();
    // document.execCommand('copy');
    const parsedHTML = el_div.innerText;
    this.set_note_text_to_local(parsedHTML);
    document.body.removeChild(el_div);
    let copyText = document.createElement('input');
    copyText.value = note_text;
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    document.body.removeChild(copyText);
    this._toastr.success('', 'Copied Successfully', this._toastrUtility.basic_configuration);
  }

  set_note_text_to_local(note) {
    const lg_clipboard = JSON.parse(localStorage.getItem('lg_clipboard'));
    lg_clipboard.case_note = note;
    localStorage.setItem('lg_clipboard', JSON.stringify(lg_clipboard));
  }

  toggle_expand_function() {
    this.toggle_expand = !this.toggle_expand;
    this.dataList.map(data => {
      data['visible'] = this.toggle_expand
    })
  }
  
  open_modal(note){
    $('#survey-feedback').modal({ backdrop: 'static', keyboard: false })
    this.get_campaign_feedback(note)
  }
  get_campaign_feedback(note){
    this._common_service.get_campaign_feedback({'case_note_id':note.id}).subscribe(res => {
      if(res.errCode == 0){
        this.survey_config.form_mode = 'read_mode';
        this.survey_questionaire = res.datarec.questionnaire;
        this.survey_answers = res.datarec.answers;
      }
    })
  }
}
