import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { EnvService } from './env.service';
import { LiveChatService } from './live-chat.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

	public io: any;

  constructor(
    private env: EnvService,
    private chat_store: LiveChatService
  ) {}

  initiate_connection(params) {
  	this.io = io(this.env.io_url, {autoConnect: false,query: params});
    // const data = this.io.connect();
  	// data.on('connect',() => {
    //   this.chat_store.live_chat_store.agent_socket_session_id = data.id;
      
    // })

    // this.io.on('error', function(){
    //   console.log("error")
    // });
    this.io.on("connect_error", function(error) {
      console.log(error )
      this.io.close();
    });
    return this.io.connect();
  }

  socket_event(event_type, room, data=null) {
  	try {
      this.io.emit(event_type, {"event_type": event_type, "room": room, "data": data});
    } catch (error) {
      alert("Not connected to Chat server");
      console.log(error)
    }
  }

  get_data_all() {
  	return Observable.create((observer) => {
      this.io.on('data_out_all', (data) => {
        observer.next(data);
      });
    });
  }

}
